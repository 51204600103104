import React from 'react';

import { SiteLayout } from '../../components/Layout/SiteLayout';
import {
  SectionHeader,
  ServicesScreen,
  OnlineShoppingScreen,
  CaseStudyScreen,
  PlatformScreen,
  StoreScreen,
  SectionFooter,
} from '../../components/forECommerce';

export const frontmatter = {
  title: 'Image & file CDN for eCommerce',
  description:
    'Keep every customer nanoseconds away with Uploadcare: the complete image and file pipeline trusted by 1000s of webshops and eCommerce platforms.',
};

const ECommerce = ({ location }) => (
  <SiteLayout meta={{ ...frontmatter }} pathName={location.pathname} isDark>
    <SectionHeader />
    <StoreScreen />
    <ServicesScreen />
    <CaseStudyScreen />
    <OnlineShoppingScreen />
    <PlatformScreen />
    <SectionFooter />
  </SiteLayout>
);

export default ECommerce;
