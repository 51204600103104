import React from 'react';

import { Container } from '@uc-common/container';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';
import { ButtonTypeEnum } from '@uc-common/button';
import { Quote } from '~/src/components/forSolutions';
import { Link } from '~/src/components/Link';
import { MediaPiplineScreenButton } from '~/src/components/forELearning/MediaPiplineScreen/styles/MediaPiplineScreen.styles';
import {
  SolutionsSubHeading,
  SolutionsDescription,
} from '~/src/components/forSolutions/styled-components';
import {
  OnlineShoppingSection,
  OnlineShoppingIntegrationsTitle,
  OnlineShoppingScreenIntegrations,
} from './styles/OnlineShoppingScreen.styles';

export const OnlineShoppingScreen = () => (
  <OnlineShoppingSection data-navbar-theme="dark">
    <Container>
      <SolutionsSubHeading>
        All you need to build faster, more responsive online shopping
      </SolutionsSubHeading>
      <SolutionsDescription>
        Keep your customers nanoseconds away. Uploadcare is a complete media pipeline that plays
        well with every eCommerce stack, from Shopify templates to custom-built B2B sales machines.
      </SolutionsDescription>

      <MediaPiplineScreenButton
        forwardedAs={Link}
        to="/accounts/signup/"
        type={ButtonTypeEnum.PRIMARY}
        data-analytics="btn_signup_1"
      >
        Start building for free
      </MediaPiplineScreenButton>
    </Container>

    <OnlineShoppingIntegrationsTitle>We SDK-speak your language</OnlineShoppingIntegrationsTitle>
    <OnlineShoppingScreenIntegrations />

    <Quote
      logoSrc={logos[BuzzLogoIdEnum.MOZILLA].src}
      logoAlt="Mozilla logo"
      quoteText="It's the most painless, enjoyable flow out there. Awesome product. We all love Uploadcare dearly."
      avatarSrc="https://ucarecdn.com/6bf9903e-584d-459c-b621-819a5bf4910a/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      avatarAlt="Chris Van Wiemeersch | UX Engineer"
    />
  </OnlineShoppingSection>
);
