import React from 'react';

import { ButtonTypeEnum } from '@uc-common/button';

import { Clients } from '~/src/components/Clients';
import { Link } from '../../Link';
import { GradientSuperHeading } from '../../Typography';
import {
  Heading,
  StyledContainer,
  StyledSection,
  StyledSubText,
  SectionHeaderButton,
} from './styles/SectionHeader.styles';

export const SectionHeader = ({ btnSignUpProps = {} }) => (
  <StyledSection>
    <StyledContainer>
      <GradientSuperHeading>Image & file CDN for eCommerce</GradientSuperHeading>
      <Heading>
        Turn product pictures into revenue with next-gen CDN and image processing for eCommerce
      </Heading>

      <StyledSubText>
        Keep every customer nanoseconds away with Uploadcare: the complete image and file pipeline
        trusted by 1000s of webshops and eCommerce platforms.
      </StyledSubText>

      <SectionHeaderButton
        forwardedAs={Link}
        to="/accounts/signup/"
        type={ButtonTypeEnum.PRIMARY}
        data-analytics="btn_signup_0"
        {...btnSignUpProps}
      >
        Get started for free
      </SectionHeaderButton>

      <Clients />
    </StyledContainer>
  </StyledSection>
);
