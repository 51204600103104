import styled, { css } from 'styled-components';
import { Container as Wrapper } from '@uc-common/container';

export const StoreScreenSection = styled(Wrapper)`
  padding-top: 100px;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StoreScreenCardsContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-top: 40px;

    ${tablet()} {
      grid-template-columns: repeat(3, 1fr);
    }

    ${laptop()} {
      margin-top: 50px;
      grid-gap: 13px;
    }
  `
);
