import React from 'react';

import { Container } from '@uc-common/container';
import { ButtonTypeEnum } from '@uc-common/button';

import { Section } from '~/src/components/forMain/Section';
import { FeatureCard } from '~/src/components/forSolutions';
import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import { Link } from '~/src/components/Link';
import {
  PlatformScreenGradientSuperHeading,
  PlatformScreenFeaturesGrid,
  PlatformScreenButtonWithArrowText,
  PlatformScreenButtonWithArrow,
} from './styles/PlatformScreen.styles';
import { data } from './data';

export const PlatformScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <PlatformScreenGradientSuperHeading>Uploadcare platform</PlatformScreenGradientSuperHeading>
      <SolutionsSubHeading>Platform features for eCommerce</SolutionsSubHeading>

      <PlatformScreenFeaturesGrid>
        {data.map(({ title, text }) => (
          <FeatureCard key={title} title={title} text={text} />
        ))}
      </PlatformScreenFeaturesGrid>

      <PlatformScreenButtonWithArrow
        forwardedAs={Link}
        to="/features/"
        type={ButtonTypeEnum.PRIMARY}
        data-analytics="btn_platform-screen_explore-features"
      >
        <PlatformScreenButtonWithArrowText>Explore all features</PlatformScreenButtonWithArrowText>
      </PlatformScreenButtonWithArrow>
    </Container>
  </Section>
);
