import React from 'react';

import { Container } from '@uc-common/container';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';

import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import {
  IntegrationSection,
  IntegrationLogo,
  IntegrationSectionArrowIcon,
  IntegrationSectionArrowButtonText,
  IntegrationSectionArrowButton,
  IntegrationSectionParagraph,
  IntegrationSectionTitle,
} from '~/src/components/forSolutions/IntegrationSection';
import { Section } from '../../forMain/Section';
import { FeaturesScreenGradientSuperHeading } from '../../forELearning/FeaturesScreen/styles/FeaturesScreen.styles';

export const CaseStudyScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <FeaturesScreenGradientSuperHeading>Case study</FeaturesScreenGradientSuperHeading>
      <SolutionsSubHeading>
        Uploadcare powers the speediest eCommerce platform out there
      </SolutionsSubHeading>
    </Container>

    <IntegrationSection>
      <IntegrationLogo src={logos[BuzzLogoIdEnum.SHOGUN_BW].src} alt="Shogun logo" />
      <IntegrationSectionTitle>Leading file uploader</IntegrationSectionTitle>
      <IntegrationSectionParagraph>
        With headless eCommerce and a drag-and-drop builder, Shogun makes online stores blazing-fast
        to load, and even faster to build. And with Uploadcare under the hood, they cranked that
        speed to fifth gear, saving hundreds of dev-hours and hosting expenses with all things image
        handled.
      </IntegrationSectionParagraph>
      <IntegrationSectionArrowButton to="/customers/shogun/" data-analytics="btn_shogun">
        <IntegrationSectionArrowButtonText>Read more</IntegrationSectionArrowButtonText>
        <IntegrationSectionArrowIcon />
      </IntegrationSectionArrowButton>
    </IntegrationSection>
  </Section>
);
