import React from 'react';
import { Container } from '@uc-common/container';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';

import { SmallCard, Quote } from '~/src/components/forSolutions';
import {
  SolutionsSubHeading,
  SolutionsDescription,
} from '~/src/components/forSolutions/styled-components';
import { Section } from '../../forMain/Section';
import { StoreScreenCardsContainer } from './styles/StoreScreen.styles';

export const StoreScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <SolutionsSubHeading>Online store without file handling chores</SolutionsSubHeading>
      <SolutionsDescription>
        Upload product pics. Photoshop mobile versions. Pray the servers will hold on Black Friday.
        Uploadcare takes these worries off your back, so you can focus on – you guessed it –
        selling.
      </SolutionsDescription>
      <StoreScreenCardsContainer>
        <SmallCard
          title="Complete file pipeline for eCommerce"
          uuid="c2370dad-0150-4283-8e0f-a3f0089bbbea"
        />
        <SmallCard
          title="80% faster website and image loading"
          uuid="7424b470-efcc-48c4-8412-9d1a11e01875"
        />
        <SmallCard
          title="Easy file management and global CDN"
          uuid="b62b40c2-f8ba-4e10-a6cf-2e7771315bc7"
        />
      </StoreScreenCardsContainer>
    </Container>

    <Quote
      logoSrc={logos[BuzzLogoIdEnum.CINDICATOR].src}
      logoAlt="Cindicator logo"
      quoteText="Before using Uploadcare, I just did all the image processing myself
      with scripts. It was a nightmare. It’s a lot more work, and it’s not
      interesting work."
      avatarSrc="https://ucarecdn.com/1c1b5393-893e-4d34-bb65-561134a02de4/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      avatarAlt="Sergey Bliznetsov | Front End Architect"
    />
  </Section>
);
