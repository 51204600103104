export const SERVICES = [
  {
    superHeading: 'File Uploader',
    heading: 'Finally, inventory-friendly file uploads',
    paragraph:
      'Whether it’s you uploading product pics, or customers uploading print-on-demand logos, our flagship file uploader makes it a breeze. It supports any file type and size - with any traffic spikes or spotty connections.',
    options: [
      'Mobile-ready uploader',
      'Fully customizable branding',
      'All file and image types',
      '14 upload sources + image editor',
    ],
    imageUuid: '99e8696c-cd6e-48c1-9f72-981d3d16f792',
    imageOptions: { quality: 'smart', format: 'auto' },
    imageAlt: 'Inventory-friendly file uploads',
  },
  {
    superHeading: 'File management',
    heading: 'Store, protect, and manage files end-to-end',
    paragraph:
      'One cloud for your entire file lifecycle. Your uploads are always safe in our ultra-secure storage, and you can manage, convert and analyze all files from our dashboard, or with a simple API integration to your platform.',
    options: [
      'Flexible File API',
      'Convert files at scale',
      'Encryption & malware protection',
      'Rich data and AI  insights',
    ],
    imageUuid: 'f7de1cd5-35a2-4ce0-9797-79450f78f519',
    imageOptions: { quality: 'smart', format: 'auto' },
    imageAlt: 'File management',
  },
  {
    superHeading: 'CDN & content delivery',
    heading: 'Faster online store = more pages visited',
    paragraph:
      'Cached in 135+ countries and resized automatically, your images are always next to your customers. Accelerate your online store with our multi-vendor CDN — more pages visited, less cart abandoned, more sales!',
    options: [
      '99.99% uptime, no maintenance',
      'Seasonal traffic spikes: handled!',
      'Convert images with URL API',
      'Automagic crop & compression',
    ],
    imageUuid: '1fce7279-e8a2-4840-9548-9df4dd50dfaf',
    imageOptions: { quality: 'smart', format: 'auto' },
    imageAlt: 'Fast online store',
  },
];
