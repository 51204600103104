export const data = [
  {
    title: 'Leading file uploader',
    text: 'Responsive and completely customizable. Image editor and 14 upload sources built-in.',
  },
  {
    title: 'Smart image compression',
    text: 'Auto image optimization adjusts format, size, and quality to each user device. WebP & AVIF support.',
  },
  {
    title: 'Intuitive URL API',
    text: 'Adjust product pics on the fly by changing a simple URL parameter. No coding or Photoshop required!',
  },
  {
    title: 'All file & image types',
    text: 'From simple JPG, to 360-degree product tours, to invoicing docs – upload, store and convert all files.',
  },
  {
    title: 'Scalable & reliable CDN',
    text: 'Global coverage with no maintenance. Accelerate your store wherever customers are.',
  },
  {
    title: 'AI-native features',
    text: 'Crop, adjust colors, add filters, detect faces, and much more, with easy to integrate AI features.',
  },
];
