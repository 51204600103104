import styled, { css } from 'styled-components';
import { GradientSuperHeading } from '~/src/components/Typography';
import { Button } from '@uc-common/button';

export const PlatformScreenGradientSuperHeading = styled(GradientSuperHeading)`
  align-self: center;
  display: block;

  background: radial-gradient(141.01% 172.5% at 0% 0%, #b951be 0%, #9365fd 84.45%);
`;

export const PlatformScreenFeaturesGrid = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 20px;
    margin-top: 50px;

    ${tablet()} {
      grid-template-columns: 1fr 1fr;
      margin-top: 70px;
    }

    ${laptop()} {
      grid-template-columns: repeat(3, 1fr);
    }
  `
);

export const PlatformScreenButtonWithArrowText = styled.p`
  position: relative;
  transition: color 0.3s;

  &::after {
    display: inline-block;
    content: '→';
    margin-left: 0.1em;
    transition: transform 0.3s ease;
  }
`;

export const PlatformScreenButtonWithArrow = styled(Button)(
  ({ theme: { tablet, laptop } }) => css`
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    max-width: 300px;
    margin: 30px auto 0;

    ${tablet()} {
      font-size: 17px;
      margin-top: 40px;
    }

    ${laptop()} {
      font-size: 20px;
      margin-top: 50px;
      max-width: calc(33% - 20px);
    }

    &:hover {
      ${PlatformScreenButtonWithArrowText} {
        &:after {
          transform: translateX(0.15em);
        }
      }
    }
  `
);
