import styled, { css } from 'styled-components';
import { Section } from '../../../forMain/Section';

export const FooterSection = styled(Section)(
  ({ theme: { tablet, laptop } }) => css`
    padding-bottom: 120px;

    ${tablet()} {
      padding-bottom: 140px;
    }

    ${laptop()} {
      padding-bottom: 170px;
    }
  `
);

export const SectionFooterHeading = styled.h2(
  ({ theme: { tablet, laptop } }) => css`
    text-align: center;
    color: #ffffff;
    max-width: 790px;
    margin: 0 auto;
    font-size: 28px;
    line-height: 1.4;
    letter-spacing: -0.02em;

    ${tablet()} {
      font-size: 38px;
    }

    ${laptop()} {
      font-size: 42px;
    }
  `
);
